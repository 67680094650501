import React from 'react'
import { Modal, Form, Input } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { useNavigate } from 'react-router-dom'
import { useBannerActions } from 'components/context/BannerContext'
import { useTemplateActions } from 'components/context/TemplateContext'

const DupeModal = ({openModel, handleCancel, template}) => {
  const [form] = Form.useForm()
  const location = useNavigate()
  const {setBannerError} = useBannerActions()
  const { addTemplate } = useTemplateActions()
  const handleOk = async () => {
    try {
      const values = await form.validateFields()
      const response = await apiClient.templates.duplicateTemplate(template.id, {...values})
      if(response.success){
        addTemplate(response.template)
        location(`/templates/${response.template.id}`)
      }
    } catch (error) {
      console.error(error)
      setBannerError(error)
    }
  }
  return (
    <Modal title="Duplicate Template" open={openModel} onOk={handleOk} onCancel={handleCancel}>
      <Form form={form} layout="vertical" >
        <Form.Item name='name'>
          <Input placeholder="Name" defaultValue={template?.name}/>
        </Form.Item>
        <Form.Item name='tag'>
          <Input placeholder="Tag" defaultValue={template?.tag} />
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default DupeModal