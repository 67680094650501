import React, { useCallback, useEffect, useState } from 'react'
import apiClient from 'components/lib/api/apiClient'
import { Table } from 'antd'

const Billing = ({template_id}) => {
  const [billings, setBillings] = useState([])

 const fetchData = useCallback( async () => {
    try {
      const response = await apiClient.billings.getBillings(template_id)
      if(response.success){
        setBillings(response.billings)
      }
    } catch (error) {
      console.log('Failed to fetch template data:', error)
    }
  }, [template_id])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const columns = [
    { title: 'Start', dataIndex: 'period_start' },
    { title: 'End', dataIndex: 'period_end' },
    { title: 'Count', dataIndex: 'count' },
    { title: 'Increment', dataIndex: 'increment'},
  ]

  return (
    <Table
      columns={columns}
      dataSource={billings}
    />
  )
}

export default Billing